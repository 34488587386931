import React from 'react';
import styles from '../css/upgradecard.module.css';
// import App from 'next/app';

function UpgradeCard() {
  const progressPercentage = 10; 

  return (
    <div className={`${styles.upgradeCard} mt-50`}>
      <div>
          <h3 className="heading-4 fw-600">Free trial</h3>
      </div>
      <span className={styles.upgradePlan}>
          <p className='text-3 fw-600'>Projects</p>
          <p className='text-3'>10/100</p>
         </span>
      <div className={styles.planProgress}>
        <div
          className={styles.progressBar}
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <p className='text-4 color-black-3'>View Plan and Pricings</p>
    </div>
  );
}

export default UpgradeCard;