"use client";
import React from "react";
import styles from "../css/sidelinks.module.css";
import Link from "next/link";
import { usePathname } from "next/navigation";

const links = [
  {
    title: "Settings",
    url: "/user/settings",
    svgIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          d="M24.875 11.125C23.9 10.5625 23.3 9.52505 23.3 8.40005L23.3125 5.37505L19.2 3.00005L16.5875 4.52505C15.6125 5.08755 14.4125 5.08755 13.4375 4.52505L10.8125 2.98755L6.7 5.36255L6.7125 8.38755C6.7125 9.51255 6.1125 10.55 5.1375 11.1125L2.5 12.625V17.3875L5.125 18.8875C6.1 19.45 6.7 20.4875 6.7 21.6125L6.6875 23.65L9.2 22.175V21.625C9.2125 19.6 8.125 17.725 6.3625 16.7125L5 15.925V14.075L6.375 13.2875C8.1375 12.2875 9.225 10.4 9.2125 8.37505V6.80005L10.8 5.87505L12.1625 6.67505C13.9125 7.70005 16.0875 7.70005 17.8375 6.67505L19.2 5.87505L20.8 6.80005V8.37505C20.7875 10.4 21.875 12.275 23.6375 13.2875L25.0125 14.075V15.925L23.6375 16.7125C21.875 17.7125 20.7875 19.6 20.8 21.625V23.2L19.2 24.125L17.8375 23.325C16.9625 22.8125 15.9875 22.5625 15 22.5625C14.0125 22.5625 13.0375 22.8125 12.1625 23.325L8.325 25.575L10.8125 27.0125L13.425 25.4875C14.4 24.925 15.6 24.925 16.575 25.4875L19.1875 27.0125L23.3 24.6375L23.2875 21.6125C23.2875 20.4875 23.8875 19.45 24.8625 18.8875L27.4875 17.3875V12.625L24.875 11.125Z"
          fill="#2D2E2E"
        />
        <path
          d="M15 20.625C18.1 20.625 20.625 18.1 20.625 15C20.625 11.9 18.1 9.375 15 9.375C11.9 9.375 9.375 11.9 9.375 15C9.375 18.1 11.9 20.625 15 20.625ZM15 11.875C16.725 11.875 18.125 13.275 18.125 15C18.125 16.725 16.725 18.125 15 18.125C13.275 18.125 11.875 16.725 11.875 15C11.875 13.275 13.275 11.875 15 11.875Z"
          fill="#2D2E2E"
        />
      </svg>
    ),
  },
  {
    title: "Help & Center",
    url: "/user/help-center",
    svgIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          d="M15 2.5C8.1125 2.5 2.5 8.1125 2.5 15C2.5 21.8875 8.1125 27.5 15 27.5C21.8875 27.5 27.5 21.8875 27.5 15C27.5 8.1125 21.8875 2.5 15 2.5ZM15 25C9.4875 25 5 20.5125 5 15C5 9.4875 9.4875 5 15 5C20.5125 5 25 9.4875 25 15C25 20.5125 20.5125 25 15 25Z"
          fill="#2D2E2E"
        />
        <path
          d="M15.0123 22.8125C15.8751 22.8125 16.5748 22.113 16.5748 21.25C16.5748 20.387 15.8751 19.6875 15.0123 19.6875C14.1493 19.6875 13.4498 20.387 13.4498 21.25C13.4498 22.113 14.1493 22.8125 15.0123 22.8125Z"
          fill="#2D2E2E"
        />
        <path
          d="M15.4499 7.66253C13.9749 7.65003 12.7124 8.03753 11.7249 8.50003V10.975C12.4999 10.475 13.7874 9.85003 15.3749 9.85003C15.3999 9.85003 15.4124 9.85003 15.4374 9.85003C17.0624 9.86253 18.0249 10.75 18.1124 11.575C18.2124 12.675 16.9124 13.6625 14.7999 14.0875L13.9249 14.2625L13.9374 18.1125H16.1249V16.0125C19.4249 15.0625 20.4624 13.0375 20.2999 11.35C20.0749 9.25003 18.0374 7.68753 15.4499 7.66253Z"
          fill="#2D2E2E"
        />
      </svg>
    ),
  },
  {
    title: "logout",
    url: "/user/logout",
    svgIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        height="30px"
        width="30px"
        viewBox="0 0 471.2 471.2"
      >
        <g>
          <g>
            <path d="M227.619,444.2h-122.9c-33.4,0-60.5-27.2-60.5-60.5V87.5c0-33.4,27.2-60.5,60.5-60.5h124.9c7.5,0,13.5-6,13.5-13.5    s-6-13.5-13.5-13.5h-124.9c-48.3,0-87.5,39.3-87.5,87.5v296.2c0,48.3,39.3,87.5,87.5,87.5h122.9c7.5,0,13.5-6,13.5-13.5    S235.019,444.2,227.619,444.2z" />
            <path d="M450.019,226.1l-85.8-85.8c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1l62.8,62.8h-273.9c-7.5,0-13.5,6-13.5,13.5    s6,13.5,13.5,13.5h273.9l-62.8,62.8c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.8-85.8    C455.319,239.9,455.319,231.3,450.019,226.1z" />
          </g>
        </g>
      </svg>
    ),
  },
];

const SidelinksTwo = () => {
  const pathname = usePathname();
  return (
    <div className={styles.SidelinksContainer}>
      <ul className={styles.Sidelinks}>
        {links.map((link, index) => {
          const { url, svgIcon, title } = link;
          return (
            <li
              key={index}
              className={`text-3 ${pathname === url ? styles.linkActive : ""}`}
            >
              {url && (
                <Link href={url}>
                  {svgIcon}
                  <span className={`${styles.title} text-3`}>{title}</span>
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SidelinksTwo;
