"use client";
import React, { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import Sidenav from "./sidenav/Sidenav";

export default function PrivateRender({ children }) {
  const [loading, setLoading] = useState(true);
  const [path, setPath] = useState(true);
  const pathname = usePathname(); // Get the current route

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  useEffect(() => {
    const isAuthRoute =
      pathname === "/user/login" || pathname === "/user/signup";
    setTimeout(() => {
      setPath(isAuthRoute);
    }, 100);
  }, [pathname]);
  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            zIndex: "999",
            background: "white",
            width: "100dvw",
            height: "100dvh",
            color: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
        >
          loading..
        </div>
      )}
      <div className={`mainContainer ${!path ? "activateGrid" : ""}`}>
        {!path && <Sidenav />} {/* Conditionally render Sidenav */}
        <main className="mainContent">{children}</main>
      </div>
    </>
  );
}
