"use client";
import React, { createContext, useState, useEffect, useContext } from "react";
import { useRouter } from "next/navigation";
import axios from "axios";

// Create the Auth context with a default value to avoid destructuring errors
const AuthContext = createContext({
  user: null,
  loading: true,
  login: () => {},
  signup: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ user: null, loading: true });
  const router = useRouter();

  // Check if user is already logged in on initial load
  useEffect(() => {
    if (typeof window === "undefined") return;

    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get("/user/next-api/auth/me", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setAuth({ user: { ...response.data?.user, token }, loading: false });
        })
        .catch((error) => {
          console.error("Error fetching user:", error);
          setAuth({ user: null, loading: false }); // Ensure loading and user are both set
        });
    } else {
      setAuth({ user: null, loading: false });
    }
  }, [router]);

  const login = async (email, password) => {
    try {
      const res = await axios.post("/user/next-api/auth/login", {
        email,
        password,
      });
      const { token, user } = res.data;
      localStorage.setItem("token", token);
      setAuth({ user: { ...user, token }, loading: false });
    } catch (error) {
      console.error("Login failed:", error.response?.data || error);
      throw new Error(error.response?.data?.message || "Login failed");
    }
  };

  const signup = async (email, password, fullName, organization) => {
    try {
      const res = await axios.post("/user/next-api/auth/signup", {
        email,
        password,
        fullName,
        organization,
      });
      const { token, user } = res.data;
      setAuth({ user: { ...user, token }, loading: false });
      router.push("/user/dashboard");
    } catch (error) {
      console.error("Signup failed:", error.response?.data || error);
      throw new Error(error.response?.data?.message || "Signup failed");
    }
  };

  const logout = async () => {
    localStorage.removeItem("token");
    setAuth({ user: null, loading: false });
    router.push("/user/login");
  };

  return (
    <AuthContext.Provider value={{ ...auth, login, signup, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
export const useAuth = () => useContext(AuthContext);
