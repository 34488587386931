"use client";
import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

// Create the GlobalContext with a default structure to avoid destructuring errors
export const GlobalContext = createContext({
  globalData: {
    isConnectedToGA: false,
    accounts: [],
    selectedAccountId: "",
    deletedSuccess: false,
    propertiesForAccount: [],
    trafficBounceRateAILoading: false,
    projectList: [],
    milestonesError: "",
    milestonesSuccess: "",
    statusMessage: null,
    milestones: [],
    goals: [],
  },
  setGlobalData: () => {},
  fetchGoogleAnalyticsConnection: () => {},
  connectGoogleAnalytics: () => {},
  revokeGoogleAnalytics: () => {},
  createProject: () => {},
  fetchProjects: () => {},
  fetchAiSuggestion: () => {},
  deleteProject: () => {},
  setMilestones: () => {},
  fetchMilestones: () => {},
  createMilestone: () => {},
  deleteMilestone: () => {},
  editMilestone: () => {},
  fetchGoalsForMilestone: () => {},
  createGoal: () => {},
  deleteGoal: () => {},
});

export const GlobalProvider = ({ children }) => {
  const [globalData, setGlobalData] = useState({
    isConnectedToGA: false,
    accounts: [],
    milestones: [],
    goals: [],
    selectedAccountId: "",
    milestonesSuccess: "",
    deletedSuccess: false, // Set to boolean false
    propertiesForAccount: [],
    trafficBounceRateAILoading: false,
    projectList: [],
    milestonesError: "",
    statusMessage: null,
  });

  //projects
  useEffect(() => {
    fetchProjects();
  }, []);
  const fetchProjects = async () => {
    const user = { token: localStorage.getItem("token") };
    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}projects/`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      console.log("response.data: ", response.data);
      setGlobalData((prev) => ({
        ...prev,
        projectList: response.data?.msg ? [] : response.data,
        statusMessage: response.data?.msg || "No projects found",
      }));
    } catch (error) {
      console.error("Error fetching projects:", error);
      setGlobalData((prev) => ({
        ...prev,
        projectList: [],
        statusMessage: "Something went wrong while fetching projects",
      }));
    }
  };

  const createProject = async (projectData) => {
    const user = { token: localStorage.getItem("token") };

    try {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}projects/create-project`,
        projectData,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setGlobalData((prev) => ({
        ...prev,
        projectList: [...(prev?.projectList || []), response.data],
      }));
      return response.data;
    } catch (error) {
      console.error("Error creating project:", error);
    }
  };

  const deleteProject = async (id) => {
    const user = { token: localStorage.getItem("token") };
    try {
      const res = await axios.delete(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}projects/delete-project/${id}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      if (res.status === 200) {
        setGlobalData((prev) => ({
          ...prev,
          deletedSuccess: true,
          projectList: prev.projectList.filter((project) => project.id !== id),
        }));
      }
    } catch (error) {
      console.error("Error deleting project:", error);
      setGlobalData((prev) => ({
        ...prev,
        deletedSuccess: false,
      }));
    }
  };

  //google analytics
  const fetchGoogleAnalyticsConnection = async () => {
    const user = { token: localStorage.getItem("token") };
    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}google/check-connection`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setGlobalData((prev) => ({
        ...prev,
        isConnectedToGA: response.data.isConnected,
        accounts: response.data.accounts || [],
      }));
    } catch (error) {
      console.error("Error checking GA connection:", error);
    }
  };

  const connectGoogleAnalytics = async () => {
    const user = { token: localStorage.getItem("token") };
    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}google/redirect`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      return response.data.redirectUrl;
    } catch (error) {
      console.error("Error connecting Google Analytics:", error);
    }
  };

  const revokeGoogleAnalytics = async () => {
    const user = { token: localStorage.getItem("token") };

    try {
      await axios.post(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}google/revoke`,
        {},
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setGlobalData((prev) => ({
        ...prev,
        isConnectedToGA: false,
        accounts: [],
        propertiesForAccount: [],
      }));
    } catch (error) {
      console.error("Error revoking Google Analytics:", error);
    }
  };

  //Ai suggestions

  const fetchAiSuggestion = async (prompt) => {
    setGlobalData((prev) => ({
      ...prev,
      trafficBounceRateAILoading: true,
    }));
    try {
      if (!prompt) return;
      const aiResponse = await axios.post(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}generative-ai`,
        { prompt }
      );
      setGlobalData((prev) => ({
        ...prev,
        trafficBounceRateAILoading: false,
        trafficBounceRateAISuggestion: {
          result: aiResponse.data.result,
          text: aiResponse.data.text,
        },
      }));
    } catch (error) {
      console.error("Error fetching AI suggestion:", error);
    } finally {
      setGlobalData((prev) => ({
        ...prev,
        trafficBounceRateAILoading: false,
      }));
    }
  };

  //milestones

  // Fetch milestones from backend
  const fetchMilestones = async () => {
    const user = { token: localStorage.getItem("token") };

    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}/milestones`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setGlobalData((prev) => ({
        ...prev,
        milestones: response.data?.msg ? [] : response.data,
      }));
    } catch (error) {
      console.error("Error fetching milestones:", error);
    }
  };

  // Create new milestone
  const createMilestone = async (milestoneData) => {
    const user = { token: localStorage.getItem("token") };

    try {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}/milestones/create`,
        milestoneData,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      setGlobalData((prev) => ({
        ...prev,
        milestonesSuccess: "successfully created",
        milestones: [...prev.milestones, response.data],
      }));
    } catch (error) {
      if (error.status == 409) {
        setGlobalData((prev) => ({
          ...prev,
          milestonesError: error?.response?.data.msg,
        }));
      } else {
        console.error("Error creating milestone:", error);
      }
    }
  };

  // Edit existing milestone
  const editMilestone = async (id, updatedData) => {
    const user = { token: localStorage.getItem("token") };

    try {
      const response = await axios.put(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}/milestones/update/${id}`,
        updatedData,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setGlobalData((prev) => ({
        ...prev,
        milestones: prev.milestones.map((milestone) =>
          milestone.id === id ? { ...milestone, ...response.data } : milestone
        ),
      }));
    } catch (error) {
      console.error("Error editing milestone:", error);
    }
  };

  // Delete milestone
  const deleteMilestone = async (id) => {
    const user = { token: localStorage.getItem("token") };

    try {
      await axios.delete(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}/milestones/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setGlobalData((prev) => ({
        ...prev,
        milestones: prev.milestones.filter((milestone) => milestone.id !== id),
      }));
    } catch (error) {
      console.error("Error deleting milestone:", error);
    }
  };

  //create goals below

  const fetchGoalsForMilestone = async (milestoneId) => {
    const user = { token: localStorage.getItem("token") };
    try {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}goals/${milestoneId}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      setGlobalData((prev) => ({
        ...prev,
        goals: response.data?.msg ? [] : response.data,
      }));
    } catch (error) {
      console.error("Error fetching goals:", error);
    }
  };

  const createGoal = async (goalData, milestoneId) => {
    const user = { token: localStorage.getItem("token") };

    try {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}goals/create`,
        { ...goalData, milestoneId },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      // Update the global state with the new goal added
      setGlobalData((prev) => ({
        ...prev,
        goals: [...prev.goals, response.data],
      }));
    } catch (error) {
      console.error("Error creating goal:", error);
    }
  };
  const deleteGoal = async (goalId) => {
    const user = { token: localStorage.getItem("token") };
    try {
      await axios.delete(
        `${process.env.NEXT_PUBLIC_USER_BACKEND_URL}goals/${goalId}`,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      // Update the global state to remove the deleted goal
      setGlobalData((prev) => ({
        ...prev,
        goals: prev.goals.filter((goal) => goal.id !== goalId),
      }));
    } catch (error) {
      console.error("Error deleting goal:", error);
    }
  };
  return (
    <GlobalContext.Provider
      value={{
        globalData,
        setGlobalData,
        fetchGoogleAnalyticsConnection,
        connectGoogleAnalytics,
        revokeGoogleAnalytics,
        createProject,
        fetchProjects,
        fetchAiSuggestion,
        deleteProject,
        fetchMilestones,
        createMilestone,
        deleteMilestone,
        editMilestone,
        fetchGoalsForMilestone,
        createGoal,
        deleteGoal,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
export default GlobalContext;

export const useGlobal = () => useContext(GlobalContext);
