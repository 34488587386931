"use client";
import React, { useEffect } from "react";
import Sidelinks from "./components/Sidelinks";
import SidelinksTwo from "./components/SidelinksTwo";
import UpgradeCard from "./components/upgradeCard";
import { useRouter } from "next/navigation";
import { useAuth } from "@/app/user/context/AuthContext";
import * as styles from "./css/sidenav.module.css";
function Sidenav() {
  const router = useRouter();
  const { user, loading } = useAuth(); // Access user and loading from AuthContext

  useEffect(() => {
    if (!loading) {
      if (!user) {
        router.push("/user/login");
      }
    }
  }, [user, loading, router]);
  if (!user) return <></>;
  return (
    <div className={styles.sideBar}>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="215"
          height="32"
          viewBox="0 0 215 32"
          fill="none"
        >
          <path
            d="M182.867 30.016V8.69368H187.639V20.154C188.284 19.772 188.858 19.3498 189.361 18.8874C189.864 18.4048 190.297 17.9022 190.66 17.3794C191.022 16.8567 191.334 16.3239 191.596 15.781C191.858 15.218 192.08 14.6551 192.261 14.0921H197.849C197.607 14.8963 197.275 15.6805 196.852 16.4445C196.429 17.1884 195.906 17.8921 195.281 18.5556C194.657 19.199 193.922 19.772 193.076 20.2747C192.251 20.7773 191.324 21.1794 190.297 21.481V21.8128C191.626 21.5715 192.704 21.5514 193.529 21.7524C194.375 21.9334 195.04 22.2651 195.523 22.7477C196.027 23.2302 196.409 23.8133 196.671 24.4969C196.953 25.1604 197.174 25.8842 197.335 26.6683L198 30.016H192.714L192.382 27.7239C192.261 26.98 192.11 26.3064 191.929 25.7033C191.747 25.08 191.435 24.5874 190.992 24.2255C190.569 23.8636 189.915 23.6826 189.029 23.6826H187.639V30.016H182.867Z"
            fill="black"
          />
          <path
            d="M165.108 30.0159V14.0921H169.156L169.186 20.0032H169.549C169.71 18.5355 170.012 17.3392 170.455 16.4143C170.898 15.4693 171.492 14.7757 172.237 14.3334C172.982 13.891 173.888 13.6699 174.956 13.6699C175.701 13.6699 176.365 13.7905 176.949 14.0318C177.554 14.2529 178.067 14.5746 178.49 14.9968C178.913 15.4191 179.255 15.9318 179.517 16.5349C179.799 17.1381 180.01 17.8217 180.151 18.5857C180.292 19.3297 180.363 20.1339 180.363 20.9985V30.0159H175.469V21.6016C175.469 21.2397 175.439 20.8477 175.379 20.4254C175.338 19.9831 175.238 19.5709 175.077 19.1889C174.936 18.7868 174.714 18.4651 174.412 18.2238C174.11 17.9625 173.687 17.8318 173.143 17.8318C172.499 17.8318 171.935 18.0529 171.452 18.4953C170.989 18.9175 170.626 19.5709 170.364 20.4556C170.103 21.3403 169.972 22.4762 169.972 23.8635V30.0159H165.108Z"
            fill="black"
          />
          <path
            d="M154.928 30.4382C153.357 30.4382 151.968 30.1366 150.76 29.5334C149.551 28.9101 148.605 27.9752 147.92 26.7286C147.256 25.4821 146.923 23.9239 146.923 22.054C146.923 20.144 147.266 18.5757 147.95 17.3492C148.655 16.1027 149.612 15.1778 150.82 14.5746C152.048 13.9714 153.438 13.6699 154.988 13.6699C156.579 13.6699 157.969 13.9815 159.157 14.6048C160.365 15.208 161.302 16.1328 161.966 17.3794C162.631 18.6059 162.963 20.1641 162.963 22.054C162.963 23.9842 162.611 25.5725 161.906 26.8191C161.201 28.0657 160.244 28.9805 159.036 29.5636C157.828 30.1466 156.458 30.4382 154.928 30.4382ZM155.049 26.8794C155.512 26.8794 155.925 26.7889 156.287 26.608C156.67 26.4069 156.982 26.1255 157.224 25.7635C157.485 25.3815 157.687 24.909 157.828 24.3461C157.969 23.7831 158.039 23.1096 158.039 22.3254C158.039 21.5011 157.959 20.7873 157.798 20.1842C157.637 19.5609 157.415 19.0482 157.133 18.6461C156.871 18.2439 156.539 17.9424 156.136 17.7413C155.754 17.5402 155.321 17.4397 154.837 17.4397C154.374 17.4397 153.961 17.5302 153.599 17.7111C153.236 17.8921 152.924 18.1736 152.663 18.5556C152.401 18.9376 152.199 19.4201 152.058 20.0032C151.938 20.5662 151.877 21.2297 151.877 21.9937C151.877 22.818 151.948 23.5418 152.089 24.1651C152.23 24.7683 152.441 25.271 152.723 25.6731C153.005 26.0752 153.337 26.3768 153.72 26.5778C154.122 26.7789 154.566 26.8794 155.049 26.8794Z"
            fill="black"
          />
          <path
            d="M121.513 30.0159V10.1111H128.853L133.293 24.3159H133.384L137.703 10.1111H144.651V30.0159H140.12L140.21 15.027H140.059L135.136 30.0159H130.937L126.104 15.027H125.953L126.044 30.0159H121.513Z"
            fill="black"
          />
          <path
            d="M112.395 30.4382C110.986 30.4382 109.767 30.2371 108.74 29.835C107.713 29.4128 106.858 28.8297 106.173 28.0858C105.508 27.3218 105.015 26.4471 104.693 25.462C104.371 24.4768 104.209 23.4011 104.209 22.235C104.209 21.0688 104.371 19.9731 104.693 18.9477C105.015 17.9223 105.508 17.0074 106.173 16.2032C106.837 15.399 107.673 14.7757 108.68 14.3334C109.707 13.891 110.915 13.6699 112.305 13.6699C113.493 13.6699 114.52 13.8307 115.386 14.1524C116.252 14.4741 116.957 14.9265 117.5 15.5095C118.064 16.0725 118.477 16.726 118.739 17.4699C119.001 18.2138 119.111 18.9979 119.071 19.8223L114.782 20.5461C114.782 19.8424 114.671 19.2593 114.449 18.7969C114.228 18.3344 113.916 17.9926 113.513 17.7715C113.13 17.5503 112.677 17.4397 112.154 17.4397C111.711 17.4397 111.298 17.5302 110.915 17.7111C110.553 17.8921 110.231 18.1635 109.949 18.5254C109.687 18.8873 109.486 19.3598 109.345 19.9429C109.204 20.5059 109.133 21.1995 109.133 22.0239C109.133 23.0694 109.264 23.954 109.526 24.6778C109.788 25.4016 110.16 25.9445 110.643 26.3064C111.147 26.6683 111.751 26.8493 112.456 26.8493C113.221 26.8493 113.805 26.6784 114.208 26.3366C114.611 25.9747 114.882 25.5323 115.023 25.0096C115.164 24.4667 115.215 23.944 115.174 23.4413L119.585 23.8937C119.645 24.718 119.554 25.5323 119.313 26.3366C119.071 27.1207 118.658 27.8244 118.074 28.4477C117.49 29.071 116.725 29.5636 115.779 29.9255C114.852 30.2673 113.724 30.4382 112.395 30.4382Z"
            fill="black"
          />
          <path
            d="M97.2325 30.0159V14.0921H102.096V30.0159H97.2325ZM99.649 12.5841C98.7227 12.5841 98.0078 12.3931 97.5043 12.0111C97.021 11.609 96.7794 11.046 96.7794 10.3222C96.7794 9.5582 97.021 8.98519 97.5043 8.60318C98.0078 8.20106 98.7227 8 99.649 8C100.595 8 101.31 8.20106 101.794 8.60318C102.297 8.98519 102.549 9.5582 102.549 10.3222C102.549 11.046 102.297 11.609 101.794 12.0111C101.31 12.3931 100.595 12.5841 99.649 12.5841Z"
            fill="black"
          />
          <path
            d="M84.6956 30.0159V14.0921H88.8339L88.8641 20.1238H89.2265C89.3474 18.5355 89.589 17.2789 89.9515 16.354C90.314 15.409 90.7973 14.7355 91.4014 14.3334C92.0055 13.9312 92.7104 13.7302 93.5159 13.7302C93.7978 13.7302 94.0998 13.7704 94.422 13.8508C94.7644 13.9111 95.1067 14.0217 95.4491 14.1826L95.2376 19.5207C94.8349 19.2995 94.4221 19.1387 93.9992 19.0381C93.5964 18.9376 93.2239 18.8873 92.8815 18.8873C92.1968 18.8873 91.6129 19.0582 91.1295 19.4C90.6462 19.7418 90.2636 20.2545 89.9817 20.9381C89.7199 21.6016 89.5789 22.426 89.5588 23.4112V30.0159H84.6956Z"
            fill="black"
          />
          <path
            d="M79.5338 30.3779C78.3658 30.3779 77.4194 30.207 76.6944 29.8652C75.9695 29.5234 75.4056 29.0609 75.0029 28.4779C74.6202 27.8948 74.3585 27.2514 74.2175 26.5477C74.0765 25.8239 74.006 25.1001 74.006 24.3763V17.8921H72.0728L72.103 14.5746H72.7072C73.7543 14.4741 74.5095 14.1725 74.9727 13.6699C75.4358 13.1471 75.7177 12.3429 75.8184 11.2572L75.9695 10.473H78.7787V14.0921H82.4941V18.0127H78.7787V24.0144C78.7787 24.7784 78.97 25.3112 79.3526 25.6128C79.7352 25.9144 80.1984 26.0652 80.7421 26.0652C81.0643 26.0652 81.3764 26.0249 81.6785 25.9445C82.0007 25.8641 82.2826 25.7535 82.5243 25.6128V29.9556C81.9 30.1366 81.3362 30.2472 80.8327 30.2874C80.3494 30.3477 79.9164 30.3779 79.5338 30.3779Z"
            fill="black"
          />
          <path
            d="M64.1064 30.4382C62.6363 30.4382 61.3878 30.2371 60.3608 29.835C59.3338 29.4329 58.488 28.8699 57.8234 28.1461C57.179 27.4022 56.6957 26.5376 56.3735 25.5524C56.0715 24.5672 55.9204 23.5117 55.9204 22.3858C55.9204 21.1995 56.0815 20.0836 56.4037 19.0381C56.7259 17.9725 57.2092 17.0376 57.8536 16.2334C58.498 15.4291 59.3136 14.8058 60.3004 14.3635C61.2871 13.9011 62.445 13.6699 63.7741 13.6699C65.1233 13.6699 66.2812 13.9011 67.2478 14.3635C68.2346 14.8058 69.03 15.4492 69.6342 16.2937C70.2383 17.118 70.641 18.0932 70.8424 19.2191C71.0639 20.3249 71.0841 21.5614 70.9028 22.9286L59.0317 23.0794V20.4556L67.4593 20.335L66.4625 21.7524C66.6034 20.7873 66.5732 19.9932 66.3718 19.3699C66.1906 18.7265 65.8785 18.254 65.4354 17.9524C65.0126 17.6307 64.4688 17.4699 63.8043 17.4699C63.0592 17.4699 62.445 17.6609 61.9617 18.0429C61.4985 18.4249 61.1562 18.9678 60.9347 19.6715C60.7333 20.3752 60.6326 21.2096 60.6326 22.1746C60.6326 23.8233 60.9246 25.0196 61.5086 25.7635C62.1127 26.5075 62.9787 26.8794 64.1064 26.8794C64.6098 26.8794 65.0226 26.8191 65.3448 26.6985C65.6872 26.5577 65.959 26.3768 66.1604 26.1556C66.3819 25.9143 66.543 25.6329 66.6437 25.3112C66.7444 24.9895 66.7947 24.6477 66.7947 24.2858L71.2049 24.7381C71.1848 25.4217 71.0539 26.1053 70.8122 26.7889C70.5706 27.4524 70.1779 28.0657 69.6342 28.6286C69.0904 29.1715 68.3655 29.6138 67.4593 29.9556C66.5531 30.2773 65.4355 30.4382 64.1064 30.4382Z"
            fill="black"
          />
          <path
            d="M30.5097 30.0159V10.1111H37.8498L42.2902 24.3159H42.3808L46.7003 10.1111H53.6478V30.0159H49.1168L49.2075 15.027H49.0564L44.1328 30.0159H39.9341L35.101 15.027H34.95L35.0406 30.0159H30.5097Z"
            fill="black"
          />
          <path
            d="M0 14C0 11.451 2.06964 9.38462 4.62268 9.38462H18.0284C20.5815 9.38462 22.6511 11.451 22.6511 14V27.3846C22.6511 29.9336 20.5815 32 18.0284 32H4.62268C2.06964 32 0 29.9336 0 27.3846V14Z"
            fill="black"
          />
          <path
            d="M4.16041 26.4615V14.4615H8.5598L11.2212 23.0252H11.2755L13.8644 14.4615H18.0284V26.4615H15.3128L15.3671 17.4252H15.2766L12.3255 26.4615H9.809L6.91229 17.4252H6.82177L6.87608 26.4615H4.16041Z"
            fill="white"
          />
          <path
            d="M197.176 2.42578C197.504 2.42578 197.759 2.38021 197.941 2.28906C198.228 2.14583 198.371 1.88802 198.371 1.51562C198.371 1.14062 198.219 0.888021 197.914 0.757812C197.742 0.684896 197.487 0.648438 197.148 0.648438H195.762V2.42578H197.176ZM197.438 5.07422C197.914 5.07422 198.254 4.9362 198.457 4.66016C198.585 4.48568 198.648 4.27474 198.648 4.02734C198.648 3.61068 198.462 3.32682 198.09 3.17578C197.892 3.09505 197.63 3.05469 197.305 3.05469H195.762V5.07422H197.438ZM195 0H197.465C198.137 0 198.615 0.200521 198.898 0.601562C199.065 0.838542 199.148 1.11198 199.148 1.42188C199.148 1.78385 199.046 2.08073 198.84 2.3125C198.733 2.4349 198.579 2.54688 198.379 2.64844C198.673 2.76042 198.893 2.88672 199.039 3.02734C199.297 3.27734 199.426 3.6224 199.426 4.0625C199.426 4.43229 199.31 4.76693 199.078 5.06641C198.732 5.51432 198.181 5.73828 197.426 5.73828H195V0Z"
            fill="black"
          />
          <path
            d="M200.43 0H204.613V0.703125H201.188V2.44531H204.355V3.10938H201.188V5.05469H204.672V5.73828H200.43V0Z"
            fill="black"
          />
          <path
            d="M209.867 0V0.683594H207.934V5.73828H207.145V0.683594H205.211V0H209.867Z"
            fill="black"
          />
          <path
            d="M212.934 3.38672L212.062 0.851562L211.137 3.38672H212.934ZM211.656 0H212.535L214.617 5.73828H213.766L213.184 4.01953H210.914L210.293 5.73828H209.496L211.656 0Z"
            fill="black"
          />
        </svg>
      </div>
      <div className={styles.sidenavContainer}>
        <div>
          <Sidelinks />
        </div>
        <div>
          <SidelinksTwo />
          <UpgradeCard />
        </div>
      </div>
    </div>
  );
}

export default Sidenav;
