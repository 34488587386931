"use client";
import React from "react";
import styles from "../css/sidelinks.module.css";
import Link from "next/link";
import { usePathname } from "next/navigation";

const links = [
  {
    title: "Dashboard",
    url: "/user/dashboard",
    svgIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          d="M17.3125 5L13.2125 16.25H5V7.5H2.5V27.5H27.5V2.5H2.5V5H17.3125ZM5 18.75H12.3L10 25H5V18.75ZM12.6875 25L16.7875 13.75H25V25H12.6875ZM25 11.25H17.7L19.975 5H24.975L25 11.25Z"
          fill="#2D2E2E"
        />
      </svg>
    ),
  },
  {
    title: "Projects",
    url: "/user/projects",
    svgIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <g clipPath="url(#clip0_410_582)">
          <path
            d="M29.93 9.5775L15 0.625L0.0700073 9.5775L15 18.535L29.93 9.5775ZM15 3.535L25.07 9.5775L15 15.625L4.93001 9.5775L15 3.535Z"
            fill="#2D2E2E"
          />
          <path
            d="M30 17.4563L15 26.4563L0 17.4563V20.3725L15 29.3713L30 20.3725V17.4563Z"
            fill="#2D2E2E"
          />
          <path
            d="M30 12.0425L26.8563 13.9287L15 21.0425L3.14375 13.9287L0 12.0425V14.9575L15 23.9575L30 14.9575V12.0425Z"
            fill="#2D2E2E"
          />
        </g>
        <defs>
          <clipPath id="clip0_410_582">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: "Milestones",
    url: "/user/milestones",
    svgIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          d="M6.25 26.25V5H17.5L18 7.5H25V20H16.25L15.75 17.5H8.75V26.25H6.25ZM18.3125 17.5H22.5V10H15.9375L15.4375 7.5H8.75V15H17.8125L18.3125 17.5Z"
          fill="#2D2E2E"
        />
      </svg>
    ),
  },
  {
    title: "Goals",
    url: "/user/goals",
    svgIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          d="M6.25 26.25V5H17.5L18 7.5H25V20H16.25L15.75 17.5H8.75V26.25H6.25ZM18.3125 17.5H22.5V10H15.9375L15.4375 7.5H8.75V15H17.8125L18.3125 17.5Z"
          fill="#2D2E2E"
        />
      </svg>
    ),
  },
  {
    title: "Sources",
    url: "/user/sources",
    svgIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <g clipPath="url(#clip0_410_590)">
          <path
            d="M13.125 7.5H16.875C18.2537 7.5 19.375 6.37875 19.375 5V2.5C19.375 1.12125 18.2537 0 16.875 0H13.125C11.7463 0 10.625 1.12125 10.625 2.5V5C10.625 6.37875 11.7463 7.5 13.125 7.5ZM13.125 2.5H16.875V5H13.125V2.5ZM16.875 22.5H13.125C11.7463 22.5 10.625 23.6213 10.625 25V27.5C10.625 28.8787 11.7463 30 13.125 30H16.875C18.2537 30 19.375 28.8787 19.375 27.5V25C19.375 23.6213 18.2537 22.5 16.875 22.5ZM16.875 27.5H13.125V25H16.875V27.5ZM27.5 22.5H23.75C22.3713 22.5 21.25 23.6213 21.25 25V27.5C21.25 28.8787 22.3713 30 23.75 30H27.5C28.8787 30 30 28.8787 30 27.5V25C30 23.6213 28.8787 22.5 27.5 22.5ZM27.5 27.5H23.75V25H27.5V27.5ZM6.25 22.5H2.5C1.12125 22.5 0 23.6213 0 25V27.5C0 28.8787 1.12125 30 2.5 30H6.25C7.62875 30 8.75 28.8787 8.75 27.5V25C8.75 23.6213 7.62875 22.5 6.25 22.5ZM6.25 27.5H2.5V25H6.25V27.5ZM5.625 20H3.125V16.875C3.125 15.1512 4.5275 13.75 6.25 13.75H13.75V10H16.25V13.75H23.75C25.4737 13.75 26.875 15.1512 26.875 16.875V20H24.375V16.875C24.375 16.5312 24.0938 16.25 23.75 16.25H16.25V20H13.75V16.25H6.25C5.905 16.25 5.625 16.5312 5.625 16.875V20Z"
            fill="#2D2E2E"
          />
        </g>
        <defs>
          <clipPath id="clip0_410_590">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

const Sidelinks = () => {
  const pathname = usePathname();
  return (
    <div className={styles.SidelinksContainer}>
      <ul className={styles.Sidelinks}>
        {links.map((link, index) => {
          const { url, svgIcon, title } = link;
          return (
            <li
              key={index}
              className={`text-3 ${pathname === url ? styles.linkActive : ""}`}
            >
              {url && (
                <Link href={url}>
                  {svgIcon}
                  <span className={`${styles.title} text-3`}>{title}</span>
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidelinks;
